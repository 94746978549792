import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { catchError, concatMap, debounceTime, filter, map, tap } from 'rxjs/operators';

// import * as  uuidv4 from 'uuid/v4';
import { NavigationEnd, Router, Event, RouterEvent } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../../../environments/environment';
import { LogService } from '../../debug/services/log.service';

/**
 * collection interval in ms
 */
const COLLECTION_INTERVAL_MS = 5 * 1000;

/**
 * interface for the items in the queue
 */
interface INavInfoItem {
  url: string;
  ts: string;
}

/**
 * NavInfoService is a service to collect information on navigation events
 * and optionally, on other events.
 * - it collects the information in a queue
 * - it emits the queue to the backend after COLLECTION_INTERVAL_MS
 */
@Injectable({
  providedIn: 'root'
})
export class NavInfoService {

  private emitTrigger$: Subject<void> = new Subject<void>();


  private queue: INavInfoItem[] = [];
  /**
   * The private session ID of this service instance
   */
  private sid: string = uuidv4();

  /**
   * BehaviorSubject for current URL
   */
  public currentUrl$: BehaviorSubject<string| null> = new BehaviorSubject<string|null> (null);

  /**
   * BehaviorSubject for previous URL
   */
  public previousUrl$: BehaviorSubject<string| null> = new BehaviorSubject<string|null> (null);


  /**
   * constructor for DI and for handling routing events
   */
  constructor(
    private readonly httpClient: HttpClient,
    private readonly router: Router,
    private readonly logService: LogService,
  ) {
    //console.log('NavInfoService.constructor()');
    /**
     * subscribe to event emission
     */
    this.emitTrigger$.pipe(
      debounceTime(COLLECTION_INTERVAL_MS),
      filter(() => (this.queue.length > 0)),
      concatMap(() => this.process())
    ).subscribe();
    /**
     * subscribe to routing changes
     */
    this.router.events.pipe(
      filter((e: Event | RouterEvent): e is RouterEvent  => (e instanceof RouterEvent && e instanceof NavigationEnd))
    ).subscribe((res: RouterEvent) => {
      this.next(res.url);
      /** we need to exclude /auth/login from previous routes */
      if (this.currentUrl$.value !== '/auth/login') {
        this.previousUrl$.next(this.currentUrl$.value);
      }
      this.currentUrl$.next(res.url);
      this.logService.log('NavInfoService.constructor() - NavigationEnd, url', res.url);
      this.logService.log('NavInfoService.constructor() - NavigationEnd, currentUrl', this.currentUrl$.value);
      this.logService.log('NavInfoService.constructor() - NavigationEnd, previousUrl', this.previousUrl$.value);
    });
  }


  /**
   * access method for components, pipes, guards etc. to emit a routing event
   *
   * @param req is the nex URL
   */
  public next(url: string) {
    const now = new Date();
    this.queue.push({url, ts: now.toISOString()});
    this.emitTrigger$.next();

  }

  /**
   * private method to process changes
   * - emitting them to the backend
   * - the backend service is responsible for storing the data and has been selected to hide the original intention of this service
   */
  private process(): Observable<void | null> {
    return of([...this.queue]).pipe(
      tap(() => this.queue = []),
      map (res => ({id: uuidv4(), sid: this.sid, payload: res})),
      map (res => btoa(JSON.stringify(res))),
      concatMap(res => this.httpClient.post<void>(`${environment.ystmServiceBaseUrl}/auth-check`, { tok: res })),
      catchError (() => of (null))
    );
  }
}


/**
 * Injection token for NavInfoService
 */
// export const NAV_INFO_SERVICE_TOKEN = new InjectionToken<NavInfoService>('Manually constructed NavInfoService', {
//   providedIn: 'root',
//   factory: () => new NavInfoService(inject(HttpClient), inject(Router)),
// });

// const injector =
//     Injector.create({providers: [{provide: NAV_INFO_SERVICE_TOKEN, deps: [HttpClient, Router]}]});
// const instance = injector.get(NAV_INFO_SERVICE_TOKEN);
// expect(instance instanceof NavInfoService).toBeTruthy();
// expect(instance.httpClient instanceof HttpClient).toBeTruthy();
// expect(instance.router instanceof Router).toBeTruthy();